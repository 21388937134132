@import "../../styles/dependencies";

// Section title styles

.section-heading {
  background: rgba($color: $black, $alpha: 0.7);
  border-radius: 0 20px 0 0;
  display: flex;
  margin-bottom: 10px;
  padding: 10px 20px;
  position: relative;

  h2 {
    color: $primary-color;
    margin: 0;
    font-size: 24px;
  }

  &__header {
    align-items: center;
    display: flex;
    font-size: 22px;
    padding: 0;
  }

  .button {
    margin: 5px 0 5px auto;

    &--heading {
      color: $primary-color;
    }
  }

  @include breakpoint($large) {
    padding: 10px 20px;

    .button--heading {
      font-size: 24px;
    }
  }
}
