//***********************************
// Base presets
//***********************************
$max-width: 1128px;

//***********************************
// Breakpoints
//***********************************
$xsmall: "min-width: 23.750em"; // 380px
$small: "min-width: 30em"; // 480px
$medium: "min-width: 48em"; // 768px
$large: "min-width: 60em"; // 960px
$xlarge: "min-width: 64em"; // 1024px
$xxlarge: "min-width: 81.25em"; // 1300px
$xxxlarge: "min-width: 90em"; // 1440px
$xxxxlarge: "min-width: 100em"; // 1600px

//***********************************
// Gutter padding
//***********************************
$mobile-gutter: 24px;
$tablet-gutter: 32px;
$desktop-gutter: 40px;

//***********************************
// Colour variables
//***********************************

// Base
$lilac: #7989fc;
$lavender: #987eff;
$anakiwa: #86f6ff;
$orange: #ff9900;
$pink: #ff3399;
$yellow: #ecf284;
$purple: #4a027e;
$blue: #0000ff;
$whispering-mist: #b2b2b2;
$electric-dream: #ff55ff;

// Colors
$lilac: #7989fc;

// Dark

// Light
$light-blue: #424283;
$light-grey: #cccccc;
$light-purple: #987eff;

// Md
$mid-grey: #555555;

// Grayscale
$white: #ffffff;
$black: #000000;
$grey: #2f2f2f;

// Validation
$red: #ff0000;
$green: #2c9b4c;

// Social media colors
$twitter: #454545;
$linkedin: #3275b0;
$whatsapp: #65d072;
$imdb: #fec601;

// Theme
$primary-color: $anakiwa;
$secondary-color: $light-blue;
$background-color: radial-gradient(ellipse at top, $lilac, transparent), radial-gradient(ellipse at bottom, $primary-color, transparent);
$background-opacity-color: rgba(
  $color: $black,
  $alpha: 0.5,
);
