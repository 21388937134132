// Base text element styling

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $white;
  font-family: Gothic, Arial, sans-serif;
  font-weight: normal;
  margin: 0;
  margin-bottom: 1rem;
}

h1 {
  font-size: 28px;
  line-height: 42px;
}

h2 {
  font-size: 22px;
  line-height: 33px;
}

h3 {
  font-size: 20px;
  line-height: 30px;
}

h4,
h5,
h6 {
  font-size: 18px;
  line-height: 28px;
}

@include breakpoint($large) {
  h1 {
    font-size: 34px;
    line-height: 41px;
  }

  h2 {
    font-size: 28px;
    line-height: 42px;
  }

  h3 {
    font-size: 24px;
    line-height: 36px;
  }
}

ul {
  padding: 0;
  margin: 0;
}

ul.MuiGrid-container {
  list-style: none;
}

p,
a,
li {
  color: $white;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

a {
  cursor: pointer;
  color: $primary-color;
  position: relative;
  text-decoration: none;

  &:before {
    background: $primary-color;
    border-radius: 2px;
    bottom: -2px;
    content: "";
    height: 1px;
    left: 0;
    opacity: 1;
    position: absolute;
    transition: width ease 0.2s, opacity ease 0.3s;
    width: 100%;
  }

  @include hover {
    text-decoration: none;

    &:before {
      opacity: 10;
      width: 0;
    }
  }

  &.underlined {
    &:before {
      opacity: 1;
      width: 100%;
    }

    @include hover {
      &:before {
        opacity: 0;
        width: 0;
      }
    }
  }
}

*:focus-visible {
  outline: 2px solid $primary-color;
}
