// Footer Styles
@import "../../styles/dependencies";

.header {
  align-items: center;
  background: $black;
  display: flex;
  width: 100%;

  &__inner {
    display: flex;
    justify-content: center;
    width: 100%;

    h1 {
      color: $primary-color;
      font-size: 24px;
      font-weight: lighter;
      margin: 0;
      padding: 10px 0;
      text-transform: capitalize;
    }
  }
}
