@import "../../styles/mixins";
@import "../../styles/variables";
@import "../../styles/typography";

@-ms-viewport {
  width: device-width;
}

// Print
@media print {
  @import "../../styles/print";
}

#root {
  background: #000;
}

// Layout styles
.main-wrapper {
  background: $background-color;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  @include breakpoint($medium) {
  }
}

.inner-container {
  margin-top: 50px;
}

.section {
  &__inner {
    background: rgba($color: $black, $alpha: 0.7);
    border-radius: 0 0 0 20px;
    padding: 20px 10px 10px 20px;
    margin-bottom: 40px;
    margin-top: -10px;

    p {
      margin-top: 0;
    }
  }
}
