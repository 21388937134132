@import "./variables";

.text-glow {
  background: linear-gradient(to right, cyan, $electric-dream, cyan);
  background-clip: text;
  color: transparent;
  animation: glow 8s ease-in-out infinite;
  background-size: 400% 100%;
  margin-bottom: 0;
}

.glow {
  background: linear-gradient(to right, cyan, $electric-dream, cyan);
  animation: glow 5s ease-in-out infinite;
  background-size: 400% 100%;
  margin-bottom: 0;
}

.fade-in {
  animation: fade-in 0.5s ease-in-out;
}

.fade-in-slow {
  animation: fade-in 3s ease-in-out;
}

.scale {
  animation: scale 1s ease-in-out;
}

// Animations
@keyframes glow {
  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

@keyframes scale {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.01;
  }
  100% {
    scale: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes icon-glow {
  0% {
    fill: $blue;
  }
  25% {
    fill: $lilac;
  }
  50% {
    fill: $pink;
  }
  75% {
    fill: $lilac;
  }
  100% {
    fill: $blue;
  }
}

@keyframes expand {
  0% {
    width: 0;
  }
  50% {
    width: 300px;
  }
  100% {
    width: 0;
  }
}

@keyframes rotation {
  100% {
    transform: rotate(calc(var(--rotate, 1) * 1turn));
  }
}
